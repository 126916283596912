.page {
    .collegeinfo {
        background-color: $lightestblue;
        font-weight: 300;
        strong {
            font-weight: 600; }
        font-size: 11px;
        line-height: 1.2em;
        span.nobreak {
            white-space: nowrap; }
        div {
            &.socialicons {
                display: flex;
                justify-content: space-between;
                width: 146px;
                height: 26px;
                .icon {
                    svg {
                        width: 26px;
                        height: 26px; }
                    svg.youtube-icon {
                        width: 34px; }
                    a svg {
                        path, circle {
                            fill: $darkestblue; } }
                    a:hover svg {
                        path, circle {
                            fill: $lightblue; } }
                    a, a:hover, a:active, a:focus, a:visited {
                        text-decoration: none; } } }
            &.contact {
                padding: 9px 0 0 0;
                a {
                    color: $darkestblue;
                    &:hover {
                        color: $lightblue; } } }
            &.aut-icon {
                padding: 10px 0 10px 0;
                svg {
                    width: 146px;
                    height: 61px; }
                a svg {
                    .clrFill {
                        fill: $darkestblue; }
                    .clrStroke {
                        stroke: $darkestblue; } }
                a:hover svg {
                    .clrFill {
                        fill: $lightblue; }
                    .clrStroke {
                        stroke: $lightblue; } } }
            &.links {
                padding-top: 15px;
                a {
                    color: $darkestblue;
                    &:hover {
                        color: $lightblue; } } } } }
    &.secondarypage .collegeinfo div.links {
        text-align: left;
        ul {
            display: inline-block;
            &:last-of-type {
                padding-right: 5px; } }
        li {
            display: inline-block;
            padding-left: 0;
            padding-right: 10px; }
        div.copyright {
            display: inline-block;
            & + ul {
                padding-right: 45px; } } } }


@media only screen and (max-width: $screen-DeskM-max) {
    .page {
        .collegeinfo {
            font-size: 10px;
            div {
                &.contact {
                    padding: 5px 0 0 0; }
                &.links {
                    padding-top: 5px; } } } } }


@media only screen and (min-width: $screen-DeskXS-min) and (max-width: $screen-DeskMMS-max) {
    .page.homepage .collegeinfo {
        div.socialicons {
            width: 126px;
            height: 24px;
            .icon svg {
                width: 24px;
                height: 24px; }
            .icon svg.youtube-icon {
                width: 30px; } }
        div.act-icon {
            svg {
                width: 126px;
                height: 36px; } } } }



@media only screen and (max-width: $screen-DeskMS-max) {
    .page {
        .collegeinfo {
            font-size: 9px; } } }


@media only screen and (max-width: $screen-MobL-max) {
    .page, .page.secondarypage {
        .collegeinfo {
            font-size: 11px;
            div {
                &.contact {
                    padding: 10px 0 0 0; }
                &.links {
                    text-align: left;
                    ul {
                        display: inline-block;
                        &:last-of-type {
                            padding-right: 5px; } }
                    li {
                        display: inline-block;
                        padding-left: 0;
                        padding-right: 10px; }
                    div.copyright {
                        display: inline-block; } } } } } }

