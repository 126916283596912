
/* HomePage Styles
 ********************************* */

#bannerModal {
    .modal-dialog {
        display: flex;
        margin: 0 auto;
        height: 100%;
        width: 1000px;
        max-width: 95%;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        .modal-content {
            width: 100%;
            max-height: 95%;
            border: none;
            background: black;
            pointer-events: auto; } } }

#banner-home {
    display: flex;
    position: relative;
    width: 100%;
    min-height: 500px;
    video#banner-home-vid {
        width: 100%;
        vertical-align: bottom; } }


@media only screen and (max-width: $screen-DeskL-max) {
    #banner-home {
        max-width: inherit;
        min-height: 300px; } }

@media only screen and (max-width: $screen-DeskM-max) {
    #banner-home {
        min-height: 200px; } }

@media only screen and (max-width: $screen-MobL-max) {
    #banner-home {
        #banner-home-overlay {
            .tagline {
                padding: 1rem 2rem;
                font-size: 2rem; }
            .play {
                padding: 0.5em;
                height: 2.5rem;
                width: 2.5rem; } } } }

@media only screen and (max-width: $screen-MobM-max) {
    /* Fixes large image scaling FF/IE */
    #banner-home {
        min-height: 100px;
        #banner-home-overlay {
            .tagline {
                padding: 0.5rem;
                font-size: 1.5rem; } } } }
