// Generate rules to indent sub menus text
//
// We'll use left border to avoid messing with the padding.

@mixin sm-smbc__sub-items-indentation($amount, $chainable: "ul ", $level: 4, $chain: "") {
  @for $i from 1 through $level {
    $chain: $chain + $chainable;
    #{$chain} a,
    #{$chain} a:hover,
    #{$chain} a:focus,
    #{$chain} a:active {
      border-left: $amount * ($i + 1) solid transparent; } } }
