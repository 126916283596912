// Notes about Colours...
//    Pantone 644C - #9bb8d3

// SMBCs Variables


// Do not remove the BEGIN/END COLOURS lines as they are used by loop_swatches.
// for now, only use full 6 character hex rgb codes. (again, for loop_swatches.)
// BEGIN COLOURS
// --- Post Rebrand Colour Scheme --- :)
$white:         #ffffff;

$lightestblue:  #c9dee8;
$lightblue:     #7ca6c3;
$blueC:         #407395;
$darkblue:      #274867;
$darkestblue:   #132c42;
$lightgrey:     #d1d3d4;
$grey:          #808080;
$darkgrey:      #333333;
$green:         #00a651;
$offwhite:      #f4f4f4;
$smbcred:       #ee5c4d;
$black:         #000000;
$testcolour:    #ff00ff;
// The following are not actively used at the moment
//   and are awaiting improvements to store.
$cyan:          #0095d5;
$red:           #da2128;
// END COLOURS


// Fonts
$font-primary:      'Open Sans', sans-serif;
$font-slogan:       'BuloRounded-Black', $font-primary;
$font-sloganref:       'BuloRounded-Regular', $font-primary;
$font-headers:      'BuloRounded-Medium', $font-primary;


// Media Query Cutoff points
$screen-MobS-max:    240px;
$screen-MobM-max:    479px;
$screen-MobL-max:    767px;
$screen-DeskXS-max:  790px;
$screen-DeskS-max:   815px;
$screen-DeskMS-max:  850px;
$screen-DeskMMS-max: 900px;
$screen-DeskM-max:   999px;
$screen-DeskML-max: 1150px;
$screen-DeskL-max:  1250px;

$screen-MobM-min:    $screen-MobS-max+1;
$screen-MobL-min:    $screen-MobM-max+1;
$screen-DeskXS-min:  $screen-MobL-max+1;
$screen-DeskS-min:   $screen-DeskXS-max+1;
$screen-DeskMS-min:  $screen-DeskS-max+1;
$screen-DeskMMS-min: $screen-DeskMS-max+1;
$screen-DeskM-min:   $screen-DeskMMS-max+1;
$screen-DeskML-min:  $screen-DeskM-max+1;
$screen-DeskL-min:   $screen-DeskML-max+1;
$screen-DeskXL-min:   $screen-DeskL-max+1;
