// Ad-bar, Events list...
.aside {
    float: right;
    width: 25%;
    padding: 20px 0 0;
    .rhs-ads {
        position: relative;
        color: $white;
        font-size: 13px;
        min-height: 250px;
        img {
            vertical-align: top;
            width: 100%; }
        .text {
            position: absolute;
            text-transform: uppercase;
            top: 96px;
            left: 10px;
            em {
                display: block;
                font-style: normal;
                font-size: 27px;
                line-height: 28px;
                font-weight: 700;
                padding: 0 0 5px; }
            span {
                display: block;
                font-size: 45px;
                line-height: 48px; } }
        a {
            color: $white;
            text-decoration: underline;
            font-weight: 700;
            &:hover {
                text-decoration: none; } } }
    .events-block {
        padding: 19px 1% 97px 11%;
        position: relative; } }

@media only screen and (max-width: $screen-DeskL-max) {
    .aside {
        padding: 20px 0 0;
        .rhs-ads {
            font-size: 11px;
            line-height: 18px;
            min-height: 200px;
            .flexslider {
                max-width: inherit; }
            .text {
                top: 77px;
                left: 10px;
                span {
                    font-size: 36px;
                    line-height: 38px; }
                em {
                    font-size: 22px;
                    line-height: 26px;
                    padding: 0; } } }
        .events-block {
            padding: 15px 1% 8px 11%; } } }

@media only screen and (max-width: $screen-DeskM-max) {
    .aside {
        padding: 20px 0 0;
        .rhs-ads {
            font-size: 9px;
            min-height: 170px;
            .text {
                left: 7px;
                top: 57px;
                span {
                    font-size: 28px;
                    line-height: 30px; }
                em {
                    font-size: 17px;
                    line-height: 18px;
                    padding: 0; } } }
        .events-block {
            padding: 10px 1% 8px 10%; } } }

@media only screen and (max-width: $screen-DeskMS-max) {
    .aside .rhs-ads {
        min-height: 163px; } }

@media only screen and (max-width: $screen-DeskS-max) {
    .aside .rhs-ads {
        min-height: 158px; } }

@media only screen and (max-width: $screen-DeskXS-max) {
    .aside .rhs-ads {
        min-height: 154px; } }

@media only screen and (max-width: $screen-MobL-max) {
    .aside {
        display: none; }
    .main-holder {
        display: table;
        .two-column {
            float: none;
            width: 100%;
            display: table-footer-group; } } }

