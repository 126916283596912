#profilemenu, #profilemobilemenu {
    display: none;
    text-transform: uppercase; }

#nav {
    font-weight: 500;
    text-transform: uppercase;
    overflow: hidden;
    ul {
        float: right; }
    li {
        float: left;
        padding: 0 1px 0 42px; }
    a:hover, .active a {
        text-decoration: none;
        color: $lightblue; } }

.headsubnav {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    //clear: both
    //overflow: hidden
    //padding: 13px 0 8px 0
    //ul
    //  float: right
    //  margin: 4px 25px 0 0
    li {
        float: left;
        padding: 0 0 0 25px; }
    a {
        color: $lightblue;
        &:hover {
            text-decoration: none;
            color: $darkgrey; } }
    .active a {
        text-decoration: none;
        color: $darkgrey; }
    li.ext {
        background: url(/images/sprite.png) no-repeat 40px -129px;
        padding-right: 20px; } }


#sidebar {
    hr {
        border: 0;
        height: 0;
        border-top: 1px solid $lightgrey;
        margin: 10px 0 20px 0; }
    h2 {
        font-family: $font-primary;
        line-height: normal; } }


@media only screen and (max-width: $screen-DeskL-max) {
    .add-nav {
        ul {
            margin: 4px 25px 0 0; }
        li {
            padding: 0 1px 0 24px; } }
    #nav li {
        padding: 0 1px 0 43px; } }

@media only screen and (max-width: $screen-DeskM-max) {
    .add-nav {
        font-size: 10px;
        padding: 9px 6px 4px 0;
        ul {
            margin-top: 2px; }
        li.ext {
            background: url(/images/sprite.png) no-repeat 32px -129px;
            padding-right: 16px; } }
    #nav {
        font-size: 14px;
        li {
            padding: 0 22px 0 15px; } } }

@media only screen and (max-width: $screen-MobL-max) {
    .mobile-menu {
        display: block;
        margin: 6px 4.5% 0 0; }
    #nav, #profilemobilemenu {
        margin: 0 0 -15px;
        display: none;
        padding-top: 14px; }
    #nav ul, #profilemobilemenu ul {
        float: none;
        font-size: 18px; }
    #nav ul li, #profilemobilemenu ul li {
        float: none;
        padding: 0;
        border-top: 1px solid $lightgrey; }
    #nav ul a {
        background: $darkgrey url(../images/sprite.png) no-repeat 100% -373px;
        color: $white;
        display: block;
        padding: 9px 4% 10px; }
    #profilemobilemenu ul a {
        background: $darkgrey url(../images/sprite.png) no-repeat 100% -373px;
        color: $white;
        display: block;
        text-align: right;
        padding: 9px 50px 10px 4%; }
    #nav ul a:hover, #profilemobilemenu ul a:hover {
        background: $lightblue url(../images/sprite.png) no-repeat 100% -373px;
        color: $white; } }
