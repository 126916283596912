.wrapper {
    width: 100%;
    overflow: hidden;
    position: relative; }

.w1 {
    position: relative;
    float: left;
    left: 50%; }

.w2 {
    position: relative;
    float: left;
    left: -50%;
    width: 100%; }

.page {
    width: 1250px; }

#main {
    clear: both;
    @include reset-box-sizing();
    .bootstrap-form {
        @include bootstrap-box-sizing(); }
    .visual img {
        vertical-align: top;
        width: 100%; }
    .bodysubnav {
        display: none; }
    .prefooter .collegeinfo {
        padding: 14px 4.5%;
        width: 91%; }
    .column-holder {
        width: 100%;
        overflow: hidden;
        .column {
            float: left;
            width: 40%;
            position: relative; }

        &#firstrow .column {
            &.majorlink {
                img {
                    vertical-align: top;
                    max-width: 100%; } }
            &.events {
                padding: 19px 0 0 2%;
                width: 18%;
                position: relative; } }
        &#secondrow .column {
            &.collegeinfo.desktop {
                padding: 19px 2% 0 2%;
                width: 16%; }
            &.collegeinfo.mobile {
                display: none; }
            &.news {
                padding: 0 1.5% 0 2%;
                width: 36.5%;
                background: $offwhite;
                span.news-category {
                    text-transform: uppercase; } }
            &.events {
                display: none; }
            &.imagedraw {
                img {
                    width: 100%;
                    display: block; } } } } }
.main-holder {
    width: 100%;
    overflow: hidden;
    margin: -5px 0 0;
    .two-column {
        float: right;
        width: 80%; } }

@media only screen and (max-width: $screen-DeskL-max) {
    .w1 {
        float: none; }
    .page {
        width: 100%; }

    #main {
        .column-holder#firstrow {
            .column.events {
                padding-top: 13px; } } }
    .main-holder {
        padding: 0 0 0; } }


@media only screen and (max-width: $screen-DeskM-max) {
    #main {
        .column-holder#firstrow {
            .column.events {
                padding-top: 9px; } } } }

@media only screen and (max-width: $screen-MobL-max) {
    #main {
        .bodysubnav {
            display: inherit;
            padding: 14px 4.5%;
            width: 91%; }
        .column-holder {
            .column {
                float: none;
                width: 100%; }
            &#firstrow .column {
                &.events {
                    display: none; } }
            &#secondrow .column {
                &.collegeinfo.desktop {
                    display: none; }
                &.collegeinfo.mobile {
                    display: block;
                    padding: 14px 4.5%;
                    width: 91%; }
                &.news {
                    padding: 0 1.5% 0 2.5%;
                    width: 96%; }
                &.events {
                    display: block;
                    padding: 14px 0 14px 4.7%;
                    width: 95.3%; } } } }
    .content-holder {
        padding: 9px 5.2% 33px 4%; } }


@media only screen and (max-width: $screen-MobM-max) {
    #main {
        #slideshow {
            display: none; } } }
