@import "../smbc-variables.sass";
@import "sm-smbc-mixins.sass";

// This file is best viewed with Tab size 4 code indentation

// -----------------------------------------------------------------------------------------------------------------
// 1. Theme Quick Settings (Variables)
// (for further control, you will need to dig into the actual CSS in 2.)
// -----------------------------------------------------------------------------------------------------------------

// ----------------------------------------------------------
// :: 1.1. Colors
// ----------------------------------------------------------

$sm_white: white !default;
$sm_black: $testcolour !default;

$sm_box-shadow: rgba(0, 0, 0, 0.25) !default;

// ----------------------------------------------------------
// :: 1.2. Breakpoints
// ----------------------------------------------------------

$sm_desktop-vp: 768px !default;

// switch from collapsible to desktop

// ----------------------------------------------------------
// :: 1.3. Typography
// ----------------------------------------------------------

$sm_font-family: $font-primary;
$sm_font-size-base: 17px;
$sm_font-weight-base: 500;
$sm_font-size-small: 12px;
$sm_line-height: 13px;

// ----------------------------------------------------------
// :: 1.4. Borders
// ----------------------------------------------------------

$sm_border-width: 0;
$sm_border-radius-base: 4px !default;

// ----------------------------------------------------------
// :: 1.5. Collapsible main menu
// ----------------------------------------------------------

// Menu box
$sm_collapsible-bg: $darkestblue;
$sm_collapsible-border-color: $lightestblue;

// Items
$sm_collapsible-item-color: $white !default;
$sm_collapsible-item-disabled-color: darken($sm_white, 20%) !default;
$sm_collapsible-item-padding-vertical: 13px !default;
$sm_collapsible-item-padding-horizontal: 20px !default;

// Items separators
$sm_collapsible-separators-color: $lightestblue;

// Toggle button (sub menu indicators)
$sm_collapsible-toggle-bg: rgba($darkestblue, 0.2) !default;

// ----------------------------------------------------------
// :: 1.6. Collapsible sub menus
// ----------------------------------------------------------

// Menu box
$sm_collapsible-sub-bg: $lightblue;

// Items text indentation for deeper levels
$sm_collapsible-sub-item-indentation: 0;

// ----------------------------------------------------------
// :: 1.7. Desktop main menu
// ----------------------------------------------------------

// Menu box
$sm_desktop-bg: transparent !default;

// Items
$sm_desktop-item-color: $sm_black !default;
$sm_desktop-item-hover-color: $sm_white !default;
$sm_desktop-item-hover-bg: $darkestblue !default;
$sm_desktop-item-highlighted-color: $sm_black !default;
$sm_desktop-item-highlighted-bg: $lightblue !default;
$sm_desktop-item-highlighted-box-shadow: 0 4px 3px $sm_box-shadow !default;
$sm_desktop-item-disabled-color: darken($sm_white, 20%) !default;
$sm_desk-item-pad-left: 25px !default;
$sm_desk-item-pad-right: 0px !default;

// Sub menu indicators
$sm_desktop-arrow-size: 6px !default;

// border-width
$sm_desktop-arrow-color: $darkestblue !default;
$sm_desktop-arrow-hover-color: $sm_white !default;
$sm_desktop-arrow-highlighted-color: $darkestblue !default;
$sm_desktop-arrow-spacing: 6px !default;

// Vertical items
$sm_desktop-vertical-item-highlighted-color: $sm_desktop-item-hover-color !default;
$sm_desktop-vertical-item-highlighted-bg: $sm_desktop-item-hover-bg !default;
$sm_desktop-vertical-item-padding-vertical: 10px !default;
$sm_desktop-vertical-item-padding-horizontal: 20px !default;

// ----------------------------------------------------------
// :: 1.8. Desktop sub menus
// ----------------------------------------------------------

// Menu box
$sm_desktop-sub-bg: $lightblue;
$sm_desktop-sub-box-shadow: 0 4px 3px $sm_box-shadow !default;
$sm_desktop-sub-padding-vertical: 8px !default;
$sm_desktop-sub-padding-horizontal: 0 !default;

// Items
$sm_desktop-sub-item-color: $white;
$sm_desktop-sub-item-hover-color: $white !default;
$sm_desktop-sub-item-hover-bg: $darkestblue !default;
$sm_desktop-sub-item-disabled-color: lighten($sm_black, 50%) !default;
$sm_desktop-sub-item-padding-vertical: 10px !default;
$sm_desktop-sub-item-padding-horizontal: 20px !default;

// -----------------------------------------------------------------------------------------------------------------
// 2. Theme CSS
// -----------------------------------------------------------------------------------------------------------------

// ----------------------------------------------------------
// :: 2.1. Collapsible mode (mobile first)
// ----------------------------------------------------------

// calc item height and sub menus toggle button size
$sm_item-height: $sm_line-height + $sm_collapsible-item-padding-vertical * 2;

// set toggle button size to 80% of item height
$sm_toggle-size: floor($sm_item-height * 0.8);
$sm_toggle-spacing: floor($sm_item-height * 0.1);

// Main menu box
.sm-smbc {
    &.collapsed {
        display: none; }
    border-top: $sm_border-width solid $sm_collapsible-border-color;
    border-bottom: $sm_border-width solid $sm_collapsible-border-color;
    background: $sm_collapsible-bg;
    // Main menu items
    a {
        &,
        &:hover,
        &:focus,
        &:active {
            padding: $sm_collapsible-item-padding-vertical $sm_collapsible-item-padding-horizontal;
            /* make room for the toggle button (sub indicator) */
            padding-right: $sm_collapsible-item-padding-horizontal + $sm_toggle-size + $sm_toggle-spacing;
            color: $sm_collapsible-item-color;
            font-family: $sm_font-family;
            font-size: $sm_font-size-base;
            font-weight: normal;
            line-height: $sm_line-height;
            text-decoration: none;
            &.navsearch {
                display: none; } }
        &.current {
            font-weight: bold; }
        &.disabled {
            color: $sm_collapsible-item-disabled-color; }
        // Toggle buttons (sub menu indicators)
        span.sub-arrow {
            @extend .glyphicon;
            @extend .glyphicon-menu-down;
            position: absolute;
            left: auto;
            top: 10px;
            right: 20px; }
        // Change + to - on sub menu expand
        &.highlighted span.sub-arrow {
            @extend .glyphicon-menu-up; } }
    // Main menu items separators
    li {
        border-top: 1px solid $sm_collapsible-separators-color; }
    > li:first-child {
        border-top: 0; }
    // Sub menus box
    ul {
        background: $sm_collapsible-sub-bg;
        // Sub menus items
        a {
            .leaf {
                position: absolute;
                left: auto;
                top: 14px;
                right: 20px;
                @extend .glyphicon;
                @extend .glyphicon-menu-right; }
            &,
            &:hover,
            &:focus,
            &:active {
                font-size: $sm_font-size-small;
                // add indentation for sub menus text
                border-left: $sm_collapsible-sub-item-indentation solid transparent; } }
        // Add indentation for sub menus text for deeper levels
        @include sm-smbc__sub-items-indentation($sm_collapsible-sub-item-indentation); } }

// ----------------------------------------------------------
// :: 2.2. Desktop mode
// ----------------------------------------------------------

@media (min-width: $sm_desktop-vp) {
    /* Switch to desktop layout
     *-----------------------------------------------
     *   These transform the menu tree from
     *   collapsible to desktop (navbar + dropdowns)
     *----------------------------------------------- */
    /* start... (it's not recommended editing these rules) */
    .sm-smbc {
        &.collapsed {
            display: block; }
        ul {
            position: absolute; }
        li {
            float: left; }
        &.sm-rtl li {
            float: right; }
        ul li, &.sm-rtl ul li, &.sm-vertical li {
            float: none; }
        a {
            white-space: nowrap; }
        ul a, &.sm-vertical a {
            white-space: normal; }
        .sm-nowrap > li > a, .sm-nowrap > li > :not(ul) a {
            white-space: nowrap; } }
    /* ...end */
    // Main menu box
    .sm-smbc {
        border-top: 0;
        border-bottom: 0;
        background: transparent;
        // Main menu items
        li.active > a {
            &,
            &:hover,
            &:focus,
            &:active,
            &.highlighted {
                color: $lightblue; }
            &:hover,
            &:focus,
            &:active {
                color: $darkestblue; } }
        a {
            &,
            &:hover,
            &:focus,
            &:active,
            &.highlighted {
                background: inherent;
                padding: 13px $sm_desk-item-pad-right 11px $sm_desk-item-pad-left;
                color: $darkestblue;
                &.navsearch {
                    display: inherit;
                    padding-top: 0; } }
            &:hover,
            &:focus,
            &:active {
                color: $lightblue; }
            &.disabled {
                background: transparent;
                color: $darkgrey;
                @include box-shadow(none); }
            // Make room for the sub arrows
            //&.has-submenu
            //  //padding-right: $sm_desktop-item-padding-horizontal + 8px + $sm_desktop-arrow-spacing
            // Sub menu indicators
            span.sub-arrow {
                background: transparent;
                display: none; }
            &:hover span.sub-arrow,
            &:focus span.sub-arrow,
            &:active span.sub-arrow {
                border-color: $sm_desktop-arrow-hover-color transparent transparent transparent; }
            &.highlighted span.sub-arrow {
                border-color: $sm_desktop-arrow-highlighted-color transparent transparent transparent; }
            &.disabled span.sub-arrow {
                border-color: $sm_desktop-arrow-color transparent transparent transparent; }
            // reset mobile first style
            &.highlighted span.sub-arrow:before {
                display: none; } }
        // No main menu items separators
        li {
            border-top: 0; }
        // Sub menus box
        ul {
            border: 0;
            padding: 0;
            background: transparent;
            // Sub menus items
            li {
                background: $lightblue;
                width: 235px;
                border-top: $lightestblue 1px solid;
                &:first-child {
                    border: 0;
                    margin-top: 18px;
                    @media only screen and (max-width: $screen-DeskMS-max) {
                        margin-top: 14px; } } }
            a {
                &, &:hover, &:focus, &:active {
                    border: 0 !important;
                    padding: $sm_desktop-sub-item-padding-vertical $sm_desktop-sub-item-padding-horizontal;
                    color: $sm_desktop-sub-item-color; }
                &.selected {
                    background: $darkestblue; }
                &:hover, &:focus, &:active {
                    background: $darkestblue;
                    &.selected {
                        background: $lightblue; } }
                &.disabled {
                    background: transparent;
                    color: $sm_desktop-sub-item-disabled-color; }
                .leaf {
                    &:before {
                        content: ""; } } }

            div.searchformholder {
                border: 0;
                padding: $sm_desktop-sub-item-padding-vertical $sm_desktop-sub-item-padding-horizontal;
                color: $sm_desktop-sub-item-color;
                background: $darkestblue;
                .leaf {
                    &:before {
                        content: ""; } }
                input {
                    color: $darkgrey;
                    display: inline-block;
                    width: 165px; }
                button {
                    padding-left: 3px;
                    padding-right: 3px;
                    color: $white;
                    &:hover {
                        color: $lightblue; } } } }
        // Scrolling arrows containers for tall sub menus - test sub menu: "Sub test" -> "more..." in the default download package
        span.scroll-up,
        span.scroll-down {
            position: absolute;
            display: none;
            visibility: hidden;
            overflow: hidden;
            background: $sm_desktop-sub-bg;
            height: 20px;
 }            // width and position will be set automatically by the script
        span.scroll-up-arrow {
            position: absolute;
            top: 6px;
            left: 50%;
            margin-left: -8px;
            // we will use one-side border to create a triangle so that we don't use a real background image, of course, you can use a real image if you like too
            width: 0;
            height: 0;
            overflow: hidden;
            border-width: 0 6px 8px 6px;
            // tweak size of the arrow
            border-style: dashed dashed solid dashed;
            border-color: transparent transparent $sm_desktop-arrow-color transparent; }
        span.scroll-down-arrow {
            @extend span.scroll-up-arrow;
            border-width: 8px 6px 0 6px;
            border-style: solid dashed dashed dashed;
            border-color: $sm_desktop-arrow-color transparent transparent transparent; }
        // Rigth-to-left
        // Main menu box
        &.sm-rtl {
            // Main menu items
            a {
                // Make room for the sub arrows
                &.has-submenu {
                    padding-right: $sm_desk-item-pad-right;
                    padding-left: $sm_desk-item-pad-left + 8px + $sm_desktop-arrow-spacing; }
                // Sub menu indicators
                span.sub-arrow {
                    right: auto;
                    left: $sm_desk-item-pad-left; } }
            // Vertical main menu
            &.sm-vertical {
                border-right: 0;
                border-left: $sm_border-width solid $sm_collapsible-border-color;
                // Vertical main menu items
                a {
                    border-radius: 0 $sm_border-radius-base $sm_border-radius-base 0;
                    // No need for additional room for the sub arrows
                    &.has-submenu {
                        padding: $sm_desktop-vertical-item-padding-vertical $sm_desktop-vertical-item-padding-horizontal; }
                    // Sub menu indicators
                    span.sub-arrow {
                        right: auto;
                        left: 10px;
                        border-width: $sm_desktop-arrow-size * 0.67 $sm_desktop-arrow-size $sm_desktop-arrow-size * 0.67 0;
                        border-style: dashed solid dashed dashed;
                        border-color: transparent $sm_desktop-arrow-color transparent transparent; }
                    &:hover span.sub-arrow,
                    &:focus span.sub-arrow,
                    &:active span.sub-arrow,
                    &.highlighted span.sub-arrow {
                        border-color: transparent $sm_desktop-arrow-hover-color transparent transparent; }
                    &.disabled span.sub-arrow {
                        border-color: transparent $sm_desktop-arrow-color transparent transparent; } } }
            // Sub menus box
            ul {
                border-radius: $sm_border-radius-base 0 $sm_border-radius-base $sm_border-radius-base;
                a {
                    border-radius: 0 !important;
                    // No need for additional room for the sub arrows
                    &.has-submenu {
                        padding: $sm_desktop-sub-item-padding-vertical $sm_desktop-sub-item-padding-horizontal !important; }
                    // Sub menu indicators
                    span.sub-arrow {
                        right: auto;
                        left: 10px;
                        border-width: $sm_desktop-arrow-size * 0.67 $sm_desktop-arrow-size $sm_desktop-arrow-size * 0.67 0;
                        border-style: dashed solid dashed dashed;
                        border-color: transparent $sm_desktop-arrow-color transparent transparent; }
                    &:hover span.sub-arrow,
                    &:focus span.sub-arrow,
                    &:active span.sub-arrow,
                    &.highlighted span.sub-arrow {
                        border-color: transparent $sm_desktop-arrow-hover-color transparent transparent; }
                    &.disabled span.sub-arrow {
                        border-color: transparent $sm_desktop-arrow-color transparent transparent; } } } }
        // Vertical main menu
        // Main menu box
        &.sm-vertical {
            border-bottom: 0;
            border-right: $sm_border-width solid $sm_collapsible-border-color;
            // Main menu items
            a {
                padding: $sm_desktop-vertical-item-padding-vertical $sm_desktop-vertical-item-padding-horizontal;
                border-radius: $sm_border-radius-base 0 0 $sm_border-radius-base;
                &:hover,
                &:focus,
                &:active,
                &.highlighted {
                    background: $sm_desktop-item-hover-bg;
                    color: $sm_desktop-item-hover-color;
                    @include box-shadow(none); }
                &.disabled {
                    background: transparent;
                    color: $sm_desktop-item-disabled-color; }
                // Sub menu indicators
                span.sub-arrow {
                    right: 10px;
                    margin-top: -($sm_desktop-arrow-size * 0.67);
                    border-width: $sm_desktop-arrow-size * 0.67 0 $sm_desktop-arrow-size * 0.67 $sm_desktop-arrow-size;
                    border-style: dashed dashed dashed solid;
                    border-color: transparent transparent transparent $sm_desktop-arrow-color; }
                &:hover span.sub-arrow,
                &:focus span.sub-arrow,
                &:active span.sub-arrow,
                &.highlighted span.sub-arrow {
                    border-color: transparent transparent transparent $sm_desktop-arrow-hover-color; }
                &.disabled span.sub-arrow {
                    border-color: transparent transparent transparent $sm_desktop-arrow-color; } }
            // Sub menus box
            ul {
                border-radius: $sm_border-radius-base !important;
                // Sub menus items
                a {
                    padding: $sm_desktop-sub-item-padding-vertical $sm_desktop-sub-item-padding-horizontal; } } } } }
