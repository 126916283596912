// More Navigation...

#sidebar {
    float: left;
    font-size: 13px;
    line-height: 25px;
    padding: 5px 0 0 3%;
    width: 15%;
    h2 {
        font-size: 13px;
        font-weight: 700; } }

.sidenav {
    line-height: 110%;
    a {
        color: $grey;
        &:hover {
            color: $darkgrey;
            text-decoration: none; }
        &.selected {
            color: $darkgrey;
            font-weight: 700; } }
    li {
        margin: 6px 0 11px; } }

@media only screen and (max-width: $screen-DeskM-max) {
    #sidebar {
        padding: 8px 0 0 3.2%;
        h2 {
            line-height: 17px;
            padding: 0 0 3px; } } }

@media only screen and (max-width: $screen-MobL-max) {
    #sidebar {
        display: none; } }
