//@import "./vendor/twbs/bootstrap-sass/assets/stylesheets/_bootstrap"

.extlink {
    @extend .glyphicon;
    @extend .glyphicon-new-window;
    font-size: 0.8em;
    vertical-align: 0.1em; }

ol {
    list-style: decimal outside none;
    ol {
        list-style: lower-alpha outside none; } }

hr {
    border: 0;
    height: 0;
    border-top: 1px solid $lightgrey;
    margin: 10px 0 20px 0; }

body {
    font: 400 14px/22px $font-primary;
    color: $darkgrey;
    -webkit-text-size-adjust: 100%; }

input {
    &[type=text], &[type=password], &[type=file], &[type=submit] {
        -webkit-appearance: none;
        display: block; } }

textarea {
    -webkit-appearance: none;
    display: block; }

a {
    color: $darkgrey;
    text-decoration: none;
    &:hover {
        text-decoration: underline; } }

#main {
    .form-horizontal {
        input {
            //&[type=text]
            //    max-width: 210px
            &[name=promo_code] {
                width: 100%;
                min-width: 130px; } }
        .controls {
            margin-left: 10px;
            float: left; } }
    .form-actions {
        margin-bottom: 15px;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        a, input {
            display: inline-block; } }
    .column {
        &.news {
            font-weight: 300;
            font-size: 13px;
            line-height: 18px;
            article {
                width: 100%;
                overflow: hidden;
                background: url(../images/border-01.gif) repeat-x;
                padding: 19px 0 23px;
                &:first-child {
                    background: none; } }
            .text {
                float: left;
                width: 49%; }
            .alignleft {
                float: left;
                margin: 5px 5% 0 0; }
            .alignright {
                float: right;
                margin: 6px 1% 0 0; }
            img {
                vertical-align: top;
                width: 100%; }
            span {
                color: $grey;
                font-size: 12px;
                display: block;
                padding: 0 0 8px; }
            h2 {
                font-weight: 700;
                font-size: 13px;
                line-height: 18px; }
            .more {
                color: $grey;
                text-decoration: underline;
                text-transform: uppercase;
                font-size: 11px;
                font-weight: 700; } } }
    .bodysubnav {
        background: $lightestblue;
        a {
            color: $darkestblue;
            &:hover {
                text-decoration: none;
                color: $lightblue; } }
        .active a {
            text-decoration: none;
            color: $lightblue; } } }

@media only screen and (max-width: $screen-DeskL-max) {
    #main {
        .column {
            &.news {
                article {
                    padding: 15px 0 18px; }
                .alignleft {
                    margin: 6px 5% 0 0;
                    width: 44%; }
                .alignright {
                    margin: 7px 1% 0 0;
                    width: 45%; } } } } }


@media only screen and (max-width: $screen-DeskM-max) {
    #main {
        .column {
            &.news {
                article {
                    padding: 8px 0 13px; }
                .text {
                    font-size: 12px;
                    line-height: 17px; }
                span {
                    font-size: 11px;
                    padding: 2px 0 8px; } } } }
    #content {
        font-size: 13px;
        line-height: 20px; } }

@media only screen and (max-width: $screen-DeskS-max) {
    #main {
        .column {
            &.news {
                .text {
                    font-size: 11px; } } } } }

@media only screen and (max-width: $screen-MobL-max) {
    #main {
        .column {
            &.news {
                article {
                    padding: 16px 0 24px; }
                .alignleft {
                    margin: 6px 6% 0 0; } } } }
    #content {
        font-size: 14px;
        line-height: 22px; } }

@media only screen and (max-width: $screen-MobM-max) {
    #main {
        .column {
            &.news {
                .text {
                    line-height: 18px; }
                article {
                    padding: 8px 0 16px; } } } } }
