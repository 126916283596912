// loginbar and searchbar
#loggedinbar {
    z-index: 30;
    div {
        font-family: $font-primary;
        line-height: 22px; }
    color: $white;
    background-color: $lightblue;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 0px 10px;
    font-size: 8pt;
    min-width: 143px;
    .containmobile {
        display: none; }
    .contain {
        div {
            display: inline-block; }
        width: 100%; }
    .logout {
        float: right; }
    .msg span {
        font-weight: bold; }
    a {
        color: $white;
        &:hover {
            color: $black;
            text-decoration: none; } }
    li.profilemenu {
        width: 15px;
        display: inline; } }
#searchbar {
    display: none; }

// Global Alert (Think pandemic, famine, trump...)
#global_alert {
    background-color: #3e7297;
    color: white;
    .ga_msg {
        padding: 1em 1.2em;
        font-size: 1.3em;
        text-align: center;
        a {
            color: white;
            text-decoration: underline;
            &:hover {
                color: $lightblue; } } } }

@media only screen and (max-width: $screen-MobL-max) {
    #loggedinbar {
        position: relative;
        width: 100%;
        left: 0;
        right: inherit;
        text-align: right;
        padding: 0 5.2% 0 4%;
        margin: 0;
        height: 30px;
        .contain {
            height: 30px;
            font-size: 16px;
            color: $white;
            padding: 4px 10px 4px 0;
            a:hover {
                color: $darkestblue; } }
        .msg {
            padding-right: 15px; } }

    #searchbar {
        display: inherit;
        &.collapsed {
            display: none; }
        position: relative;
        width: 100%;
        height: 40px;
        right: 0;
        top: 100%;
        padding: 10px;
        background-color: $darkestblue;
        border-top: $lightestblue 1px solid;
        input {
            width: 85%;
            background-color: $white;
            border: 1px solid $lightgrey;
            height: 20px;
            font-size: 12px;
            line-height: 14px;
            padding: 2px 6px 3px 6px;
            color: $darkgrey; }
        button {
            width: 20px;
            height: 20px;
            cursor: pointer;
            padding: 0;
            margin: 0;
            border: 0;
            position: absolute;
            right: 10px;
            top: 10px;

            &.btn-link {
                color: $white;
                &:hover {
                    color: $lightblue; } } } } }
