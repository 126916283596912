@import "smbc-variables";

#main.courses-page {
    #filter-block {
        background-color: $lightestblue;
        padding-left: 10px;
        padding-bottom: 15px; }
    .courses {
        .course {
            clear: both;
            margin-top: 5px;
            padding-top: 15px;
            img.courseicons {
                margin: 5px 10px 5px 0;
                float: left; }
            h2 a {
                color: $lightblue;
                text-decoration: none; }
            .facts strong {
                color: $lightblue;
                font-weight: 700;
                text-transform: uppercase; }
            p.link {
                padding-bottom: 10px;
                a {
                    font-weight: 700;
                    color: $grey;
                    text-transform: uppercase;
                    font-size: 11px; } }
            .icons {
                vertical-align: top;
                font-size: 13px;
                clear: both;
                margin-bottom: 10px;
                line-height: 140%; } } }
    .courses.withfilter .course,
    .courses .course:not(:first-child) {
        border-top: 1px solid $lightgrey; }
    #filter-text {
        clear: both;
        margin-top: 5px;
        padding-top: 15px; }

    .courses-body h3 {
        margin-top: -12px;
        font-size: 22px;
        color: $lightblue;
        font-weight: 700; }
    .courses-nav {
        min-height: 50px;
        ul {
            display: flex;
            flex-wrap: wrap;
            list-style-type: none;
            margin: 0;
            padding: 0;
            font-size: 12px;
            line-height: 15px; }
        li {
            display: block;
            margin: 10px 0 5px 0;
            padding-left: 5px;
            padding-right: 20px;
            min-height: 30px;
            a.selected {
                text-decoration: none;
                font-weight: 700; } } } }
