@import "smbc-variables";

#main {
    .events, .events-block {
        background: $darkestblue;
        h2 a {
            color: $lightblue;
            font-size: 12px;
            padding: 0 0 7px; }
        ul li {
            width: 100%;
            overflow: hidden;
            padding: 7px 0 10px; }
        .date {
            width: 30px;
            font-weight: 700;
            float: left;
            text-transform: uppercase;
            text-align: center;
            color: $darkgrey;
            margin: 4px 5% 0 0;
            em {
                display: block;
                background: $white;
                font-size: 16px;
                line-height: 22px; }
            span {
                display: block;
                background: $lightblue;
                font-size: 11px;
                line-height: 13px;
                padding: 0 0 2px; } }
        .event {
            color: $white;
            width: 60%;
            float: left;
            font-weight: 300;
            font-size: 11px;
            line-height: 14px;
            h2 {
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                padding: 1px 0;
                a {
                    color: $white; } } }
        .next {
            position: absolute;
            right: 3.5%;
            bottom: 16px;
            font-size: 18px;
            color: $white;
            &:hover {
                color: $lightblue; } } } }

@media only screen and (max-width: $screen-DeskL-max) {
    #main {
        .events {
            ul li {
                padding: 0 0 17px; }
            .next {
                bottom: 10px; } }
        .events-block {
            ul li {
                padding: 7px 0 10px; }
            .next {
                bottom: 9px; } } } }

@media only screen and (max-width: $screen-DeskM-max) {
    #main {
        .events, .events-block {
            .event p {
                display: none; }
            ul li {
                padding: 0 0 11px; }
            .date {
                margin: 4px 8% 0 0; } }
        .events-block .next {
            right: 11px; } } }

@media only screen and (max-width: $screen-MobL-max) {
    #main {
        .events {
            .event p {
                display: block; }
            ul li {
                padding: 0 0 15px; }
            .date {
                margin: 4px 2% 0 0; }
            .next {
                right: 4.5%;
                font-size: 10px; } } } }

@media only screen and (max-width: $screen-MobM-max) {
    #main {
        .events {
            h2 {
                padding: 2px 0 7px; }
            .date {
                margin: 4px 4% 0 0; }
            ul li {
                padding: 0 0 14px; } } } }
