@mixin reset-box-sizing() {
    *, *:before, *:after {
        box-sizing: content-box;
        -moz-box-sizing: content-box;
        -webkit-box-sizing: content-box; } }

@mixin bootstrap-box-sizing() {
    *, *:before, *:after {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box; } }

@mixin list-date() {
    color: $lightblue;
    text-transform: uppercase;
    font-weight: bold; }

@mixin list-item() {
    clear: both;
    padding-top: 15px;
    border-top: 1px solid $lightgrey;
    margin-bottom: 8px; }
