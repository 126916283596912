
/* FlexSlider Necessary Styles
 ********************************* */

.flexslider {
    margin: 0;
    padding: 0;
    position: relative;
    &.imagedraw {
        div.loader-img {
            display: none; }
        ul.slides {
            display: inherit; }
        &.loading {
            background: url(/images/idraw-loading.gif) no-repeat center center;
            .loader-img {
                display: inherit; }
            ul.slides {
                display: none; } } } }

.flexslider {
    .slides {
        > li {
            display: none;
            -webkit-backface-visibility: hidden; }
        img {
            width: 100%;
            display: block; }
        &.feature img {
            height: auto;
            display: block; } }
    ul.slides.inner img {
        height: auto;
        display: block; } }

/* Hide the slides before the JS is loaded. Avoids image jumping */

.flex-pauseplay span {
    text-transform: capitalize; }

#main {
    .content-holder .flexslider {
        margin: 5px 0 20px;
        min-height: 200px;
        &.loading {
            background: url(/images/ajax-loader.gif) no-repeat center center; } }
    #slideshow .gallerynav {
        font-weight: 700;
        float: right;
        a {
            color: $lightblue; } }
    .flexslider .flex-caption {
        font-style: italic;
        color: $white;
        background: $darkestblue;
        padding: 3px 6px;
        margin: 0; }
    .cycle-slideshow img {
        width: 100%;
        height: auto; } }

/* Direction Nav */

.flex-direction-nav {
    *height: 0;
    a {
        width: 30px;
        height: 30px;
        margin: -20px 0 0;
        display: block;
        background: url(/images/bg_direction_nav.png) no-repeat 0 0;
        position: absolute;
        top: 50%;
        z-index: 10;
        cursor: pointer;
        text-indent: -9999px;
        opacity: 0;
        -webkit-transition: all .3s ease; }
    .flex-next {
        background-position: 100% 0;
        right: -36px; }
    .flex-prev {
        left: -36px; } }

.flexslider:hover {
    .flex-next {
        opacity: 0.8;
        right: 5px; }
    .flex-prev {
        opacity: 0.8;
        left: 5px; }
    .flex-next:hover, .flex-prev:hover {
        opacity: 1; } }

.flex-direction-nav .flex-disabled {
    opacity: 0.3 !important;
    filter: alpha(opacity = 30);
    cursor: default; }

/* Clearfix for the .slides element */

.slides:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }

html[xmlns] .slides {
    display: block; }

* html .slides {
    height: 1%; }

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */

.no-js .slides > li:first-child {
    display: block; }

.flex-control-nav {
    display: none; }

.flex-direction-nav {
    display: none;
    &.history {
        display: block; } }


@media only screen and (max-width: $screen-DeskL-max) {
    .rhs-ads .flexslider {
        max-width: 195px; } }
