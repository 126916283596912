@import "headerextras.sass";
@import "navigation.sass";

#header {
    width: 100%;
    height: 129px;
    padding: 0;
    position: relative;
    div.smbclogo {
        width: 362px;
        height: 129px;
        padding-top: 21px;
        padding-left: 2%;
        img.logo1 {
            width: 295px;
            height: 90px; } }
    .headsubnav {
        position: absolute;
        right: 20px;
        top: 35px;
        a {
            color: $lightblue;
            &:hover {
                text-decoration: none;
                color: $darkestblue; } }
        .active a {
            text-decoration: none;
            color: $darkestblue; } }

    div.mobicon, span.navsearchicon {
        svg {
            width: 26px;
            height: 26px; } }
    div.mobicon {
        display: none;
        position: absolute;
        right: 4.6%;
        &.search {
            top: 70px; }
        &.menu {
            top: 30px; }
        a {
            &:hover.untouched, &:active, &:focus, &:visited {
                text-decoration: none; }
            svg path {
                fill: $darkblue; }
            &:hover.untouched svg path {
                fill: $lightblue; } }
        a.collapsed {
            svg path {
                fill: $darkblue; }
            &:hover.untouched svg path {
                fill: $lightblue; } } }
    a.navsearch {
        svg path {
            fill: $darkblue; }
        &.highlighted {
            svg path {
                fill: $lightblue; } } }

    .headnavholder {
        clear: both; }
    #headnav {
        position: absolute;
        z-index: 100;
        right: 20px;
        bottom: 18px; } }

.secondarypage #header {
    border-bottom: solid 1px $lightgrey;
    margin-bottom: 15px; }

@media only screen and (max-width: $screen-DeskMS-max) {
    #header {
        height: 100px;
        div.smbclogo {
            width: 280px;
            height: 100px;
            padding-top: 16px;
            img.logo1 {
                width: 229px;
                height: 70px; } }
        .headsubnav {
            top: 28px; }
        #headnav {
            position: absolute;
            bottom: 14px; } } }

@media only screen and (max-width: $screen-MobL-max) {
    #header {
        height: inherit;
        div.smbclogo {
            width: 362px;
            height: 129px;
            padding-top: 21px;
            padding-left: 4.6%;
            img.logo1 {
                width: 295px;
                height: 90px; } }
        #headnav {
            position: inherit;
            right: inherit;
            bottom: inherit; }
        .headsubnav {
            display: none; }
        div.mobicon {
            display: inherit;
            right: 4.6%;
            &.search {
                top: 70px; }
            &.menu {
                top: 30px; } }
        .holder {
            float: none;
            width: 100%;
            clear: both;
            padding: 0; } } }

@media only screen and (max-width: $screen-MobM-max) {
    #header {
        div.smbclogo {
            width: 280px;
            height: 100px;
            padding-top: 16px;
            img.logo1 {
                width: 229px;
                height: 70px; } }
        div.mobicon {
            &.search {
                top: 55px; }
            &.menu {
                top: 22px; } } } }

