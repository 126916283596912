#footer {
    background: none;
    color: $lightblue;
    padding: 17px 0;
    div.slogan {
        line-height: normal;
        overflow: hidden;
        font-family: $font-sloganref;
        p {
            text-align: center;
            &.motto {
                .quote {
                    font-family: $font-slogan; }
                font-size: 16px;
                letter-spacing: 0.05em; }
            &.ref {
                font-size: 10px; } } } }

