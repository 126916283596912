@import "smbc-variables";
@import "smbc-mixins";

#content, .contentregion {
    .clearFloat {
        clear: both; }
    font-family: $font-primary;
    float: left;
    width: 73%;
    margin-bottom: 25px;
    a {
        text-decoration: underline;
        &.btn {
            text-decoration: inherit; } }
    audio {
        margin: 15px 0;
        width: 100%; }
    em {
        font-style: italic; }
    strong {
        font-weight: 700; }
    h1 {
        font-family: $font-headers;
        font-size: 22px;
        line-height: 26px;
        font-weight: 700;
        padding: 10px 0 20px;
        a {
            text-decoration: none;
            &:hover {
                text-decoration: none; } } }
    h2 {
        font-family: $font-headers;
        font-size: 22px;
        line-height: 26px;
        font-weight: 700;
        padding: 0 0 5px;
        color: $lightblue; }
    h3 {
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        margin-bottom: 5px; }
    h4 {
        color: $lightblue;
        font-weight: bolder;
        padding: 0 0 6px; }
    p {
        padding: 0 0 16px;
        word-spacing: -1px;
        a {
            ext-decoration: underline;
            &:hover {
                text-decoration: none; } }
        + ul {
            margin-top: -12px; } }
    ul {
        margin: 0px 0 22px 16px;
        li {
            background: url(../images/point.gif) no-repeat 0 10px;
            padding: 0 0 0 8px; } }
    ol {
        margin: 0 0 22px 24px;
        ol {
            margin: 0 0 0 24px; } }
    li a:hover {
        text-decoration: none; }
    img {
        max-width: 100%; }

    ul.slides {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            list-style: none;
            padding: 0;
            margin: 0; } }

    .alignleft {
        float: left;
        margin: 6px 2.6% 0 0;
        width: 34.5%;
        img {
            width: 100%;
            vertical-align: top; } }
    .content-holder {
        table tr {
            border: 1px solid $lightgrey;
            td {
                padding: 5px; } }
        .paginate {
            clear: both;
            display: block;
            border-top: 1px solid $lightgrey;
            padding-top: 25px;
            a {
                text-decoration: none; } } }
    div.modalVideos {
        .modalVidThumb {
            cursor: pointer;
            position: relative;
            float: left;
            margin: 9px;
            img {
                display: block; }
            .playit {
                position: absolute;
                top: 70px;
                left: 70px;
                z-index: 5;
                svg {
                    width: 60px;
                    height: 60px;
                    opacity: 0.65;
                    .clrFill {
                        fill: $smbcred; }
                    .clrStroke {
                        stroke: $smbcred; } } }
            &:hover .playit {
                svg {
                    .clrFill {
                        fill: white; }
                    .clrStroke {
                        stroke: white; } } } }
        & + * {
            clear: both; } }
    .pdfembed {
        height: 600px;
        width: 100%; }
    iframe.issuu {
        height: 600px;
        width: 100%;
        &.square {
            height: 365px; } }
    iframe.issuu350 {
        height: 350px; }
    .iframe-rwd {
        position: relative;
        padding-bottom: 65.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%; } }
    .talk {
        .talk_listen {
            color: $green; }
        .talk_download {
            color: $blueC; }
        .talk_watch {
            color: $smbcred; }
        clear: both;
        padding-top: 15px;
        border-top: 1px solid $lightgrey;
        margin-bottom: 8px;
        &.conversation {
            margin-bottom: 24px; }
        strong {
            font-weight: 700; }
        p {
            padding: 0;
            &.no1 span {
                @include list-date(); }
            &.no2 {
                text-transform: uppercase; }
            &.no3 {
                font-size: 12px; }
            a {
                &.green {
                    color: $green; }
                &.blue {
                    color: $lightblue; } } }
        .videoimage {
            position: relative;
            padding-top: 8px;
            img {
                width: 100%;
                height: auto; }
            a {
                position: absolute;
                display: block;
                background: url(/images/overlay-video.png) no-repeat center center;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 1; } } }
    .event-block {
        clear: both;
        padding-top: 25px;
        border-top: 1px solid $lightgrey;
        img {
            float: left;
            margin: 0 20px 25px 0; }
        h2 {
            color: $darkgrey; }
        .facts strong {
            color: $lightblue;
            font-weight: 700;
            text-transform: uppercase; }
        p.link a {
            font-weight: 700;
            color: $grey;
            text-transform: uppercase;
            font-size: 11px; } }
    .events-body h3 {
        margin-top: -12px;
        font-size: 22px;
        color: $lightblue;
        font-weight: 700; }
    #mep_0 {
        margin: 20px 0; }
    .btalk-nav {
        min-height: 40px;
        clear: both;
        a:hover {
            text-decoration: none; }
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            font-size: 14px;
            &.blue li a {
                color: $lightblue; } }
        li {
            display: block;
            float: left;
            background: none;
            padding: 0 30px 0 0;
            font-weight: 700;
            .selected {
                text-decoration: none; } } }
    .scripture {
        width: 47%;
        float: left;
        h2 {
            font-size: 14px;
            text-transform: uppercase; }
        ul {
            margin: 0; } }

    .faqs-body {
        padding-top: 5px; }

    .faq-trigger {
        font-weight: bold;
        padding-bottom: 8px;
        .accordian-toggle {
            font-size: 1em;
            cursor: pointer;
            text-decoration: none;
            span.is-collapsed,span.is-expanded {
                font-size: 0.8em; }
            span.is-collapsed {
                display: none; }
            span.is-expanded {
                display: inherit; }
            color: $lightblue;
            &:hover {
                color: $darkgrey; }
            &.collapsed {
                color: $darkgrey;
                &:hover {
                    color: $lightblue; }
                span.is-collapsed {
                    display: inherit; }
                span.is-expanded {
                    display: none; } } } } }

@media only screen and (max-width: 1250px) {
    #content p {
        letter-spacing: 0; } }

@media only screen and (max-width: 999px) {
    #content .alignleft {
        margin: 6px 3% 0 0; } }


@media only screen and (max-width: 767px) {
    #content {
        float: none;
        width: 100%;
        p {
            display: table;
            table-layout: fixed;
            width: 100%;
            img {
                height: auto;
                max-width: 100%; }
            img.twothirds {
                max-width: 60%; } }
        .alignleft {
            width: 46.1%;
            margin: 6px 6% 0 0; }
        iframe.issuu {
            height: 450px; } } }

@media only screen and (max-width: 479px) {
    #content {
        .event-block img {
            display: none; } } }

@media only screen and (max-width: 240px) {
    #content {
        p {
            img.twothirds {
                max-width: 100%; } } } }


